import React from "react"
import Styles from "./css/ty.module.scss"
import thankyouData from "./data/ty.json"
import {isChrome,isFirefox,isIeEdge,isSafari,getBrowser} from "@tightrope/lpscripts/browserdetect"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

class thankyou extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      isSafari: isSafari(),
      display: 'none',
      pageData: props.data,
      data: thankyouData
    }
    this.submitSearch = this.submitSearch.bind(this);
    this.getOs = this.getOs.bind(this);
  }



showBrowsers(browsers){
  let display = false;
  if(getBrowser() === 'ie' && browsers.includes('ie')){
    display=true;
  }
  if(getBrowser() === 'firefox' && browsers.includes('firefox')){
    display=true;
  }
  if(getBrowser() === 'chrome' && browsers.includes('chrome')){
    display=true;
  }
  if(getBrowser() === 'edge' && browsers.includes('edge')){
    display=true;
  }
  if (getBrowser() === "safari" && browsers.includes("safari")) {
    display = true;
  }
  if(getBrowser() === 'other' && browsers.includes('other')){
    display=true;
  }
  if(display)
    this.setState({display: 'block'});
  else
    this.setState({display: 'none'});
}

submitSearch(){
  let searchVal = this.state.search;
  if(window){
    let querystring = window.location.search;
    let urlParams = new URLSearchParams(querystring);
    let guid = urlParams.get('guid');
    if(guid == null){
      guid = "";
    }
    const today = new Date();
    const yy = today.getFullYear().toString().substr(-2);
    let mm = today.getMonth() + 1;
    if(mm < 10){
      mm = "0" + mm;
    }
    let dd = today.getDate();
    if(dd < 10){
      dd = "0" + dd;
    }
    let searchDate = ""+mm+dd+yy;
    let os = this.getOs();
    let tbid = "";
    if(os == "mac"){
      tbid = "236363";
    }
    if(os == "win"){
      tbid = "236371";
    }
    let url = 'https://search.getbettersearch-api.com/crx/search.php?k='+searchVal+'&action=tp&guid='+guid+'&type=Y273_F1_'+tbid+'_'+searchDate;
    window.location.href = url;
  }
}

getOs() {
  let os = null;
  if(window){
    const userAgent = window.navigator.userAgent,
        platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
        macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'mac';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'ios';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'win';
    } else if (/Android/.test(userAgent)) {
      os = 'android';
    } else if (/Linux/.test(platform)) {
      os = 'linux';
    }
  }
  return os;
}

onChange(value){
  this.setState({search: value});
}

onKeyPress(e){
  if(e.key === 'Enter'){
    this.submitSearch();
  }
}

componentDidMount() {
  if(this.props.browsers){
    this.showBrowsers(this.props.browsers);
  }
  else{
    this.setState({display: 'block'});
  }
  if(this.props.data){
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();
    this.state.isSafari = isSafari();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    if (isSafari()) {
      const browserData = Object.assign(this.state.data, this.state.data.safari);
      this.setState({ data: browserData });
    }
    if (isIeEdge()) {
      const browserData = Object.assign(this.state.data, this.state.data.edge);
      this.setState({ data: browserData });
    }


    });
  }
}
render() {
    return (
    <div className = {Styles.thankyouPageXPI}>
      <section>
        <div className={Styles.tyCard}>
          <img src = {this.state.data.btLogo} className={Styles.logo} alt="Better Search" />
          <h2>{this.state.data.heading}</h2>
          <h1>{this.state.data.copy}</h1>
          <div className={Styles.searchBar}>
            <input
              placeholder= {this.state.data.placeholder}
              className={Styles.searchInput}
              onChange={e => this.onChange(e.target.value)}
              onKeyPress={e => this.onKeyPress(e)}
            />
            <button className={Styles.searchButton} onClick={this.submitSearch}>
              <img src= {this.state.data.search} alt="Search" />
            </button>
          </div>
        </div>
        <RawFooter></RawFooter>
      </section>
    </div>

    )
  }
}

export default thankyou
